interface UploadVm {
  token: string;
}

const defUploadVm = (): UploadVm => ({
  token: '',
});

export {
  UploadVm,
  defUploadVm,
};
