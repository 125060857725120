









import { defineComponent } from '@vue/composition-api';
import { Translation } from '@/translations/Translation';

export default defineComponent({
  setup() {
    return {
      Translation,
    };
  },
});
