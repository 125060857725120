interface PostTicketVm {
  publicLink: string;
}

const defPostTicketVm = (): PostTicketVm => ({
  publicLink: '',
});

export {
  PostTicketVm,
  defPostTicketVm,
};
