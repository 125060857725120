













import { defineComponent } from '@vue/composition-api';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { useReportTicket } from '@/composables';
import { Translation } from '@/translations/Translation';
import { UploadVm } from '@/types/view-models';
import { FileVueDropZone } from '@/types/vue2-dropzone/models';
import { Attachment } from './models';

const UPLOAD_URL = `${process.env.VUE_APP_API}/uploads`;

export default defineComponent({
  name: 'app',
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: UPLOAD_URL,
        chunking: true,
        chunkSize: 500_000,
        createImageThumbnails: false,
        maxFilesize: 10,
        uploadMultiple: false,
        parallelUploads: 4,
        dictDefaultMessage: Translation.AddAttachment,
        dictRemoveFile: Translation.RemoveFile,
        addRemoveLinks: true,
      },
    };
  },
  setup() {
    const processingUuids: string[] = [];
    const attachments: Attachment[] = [];

    const { addAttachmentToken, removeAttachmentToken } = useReportTicket();

    function addAttachment(attachment: Attachment) {
      attachments.push(attachment);
      addAttachmentToken(attachment.token);
    }

    function addProcessingUuid(uuid: string) {
      processingUuids.push(uuid);
    }

    function removeProcessingUuid(uuid: string) {
      const array = processingUuids.filter((value) => value !== uuid);
      processingUuids.splice(0, processingUuids.length, ...array);
    }

    function getTokenByUuid(uuid: string) {
      const attachment = attachments.find((value) => value.uuid === uuid);
      return attachment?.token ?? '';
    }

    function onDzProcessing(file: FileVueDropZone) {
      addProcessingUuid(file.upload.uuid);
    }

    function onDzSuccess(file: FileVueDropZone) {
      const response = JSON.parse(file.xhr.responseText) as { data: UploadVm; };
      addAttachment({
        uuid: file.upload.uuid,
        token: response.data.token,
      });
      removeProcessingUuid(file.upload.uuid);
    }

    function uploadError(file: FileVueDropZone) {
      removeProcessingUuid(file.upload.uuid);
    }

    function uploadCanceled(file: FileVueDropZone) {
      removeProcessingUuid(file.upload.uuid);
    }

    function uploadRemove(file: FileVueDropZone) {
      const token = getTokenByUuid(file.upload.uuid);
      removeAttachmentToken(token);
    }

    return {
      onDzProcessing,
      onDzSuccess,
      uploadError,
      uploadCanceled,
      uploadRemove,
    };
  },
});
