/* eslint-disable camelcase */
export enum Translation {
  Send = 'Wyślij',
  AddAttachment = 'Dodaj załącznik',
  WhistleblowerSystem = 'System zgłaszania podziękowań, wniosków, skarg, zdarzeń niepożądanych',
  RemoveFile = 'Usuń plik',
  Subject = 'Tytuł',
  SubjectValidationCharLength = 'Wprowadź minimum 3 znaki',
  Success = 'Sukces',
  CopySuccessMessage = 'Skopiowano link do schowka',
  CopyErrorHeader = 'Błąd kopiowania do schowka',
  ThanksMessage = 'Dziękujemy za wysłanie zgłoszenia',
  PublicLinkMessage = 'Twoje zgłoszenie znajduje się pod linkiem',
}
