









import { defineComponent } from '@vue/composition-api';
import { useReportTicket } from '@/composables';
import {
  TextEditor,
  Upload,
  Submit,
  Subject,
} from './atoms';

export default defineComponent({
  components: {
    TextEditor,
    Upload,
    Submit,
    Subject,
  },
  setup() {
    const { send } = useReportTicket();

    function onSubmit(event: Event) {
      event.preventDefault();
      send();
    }

    return {
      onSubmit,
    };
  },
});
