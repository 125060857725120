import Vue from 'vue';
import VueCompositionAPI, {
  ref,
  Ref,
} from '@vue/composition-api';
import { axiosRequest } from '@bth/standard';
import { PostTicketSm } from '@/types/send-models';
import { PostTicketVm, defPostTicketVm } from '@/types/view-models';

Vue.use(VueCompositionAPI);

interface UseReportTicket {
  subject: Ref<string>;
  content: Ref<string>;
  attachmentTokens: string[];
  publicLink: Ref<string>;
  init(): void;
  addAttachmentToken(token: string): void;
  removeAttachmentToken(token: string): void;
  send(): void;
}

const subject = ref('');
const content = ref('');
const attachmentTokens: string[] = [];
const publicLink = ref('');

export function useReportTicket() : UseReportTicket {
  function init() {
    subject.value = '';
    content.value = '';
    attachmentTokens.splice(0, attachmentTokens.length);
    publicLink.value = '';
  }

  function addAttachmentToken(token: string) {
    attachmentTokens.push(token);
  }

  function removeAttachmentToken(token: string) {
    const filteredTokens = attachmentTokens.filter((value) => value !== token);
    attachmentTokens.splice(0, attachmentTokens.length, ...filteredTokens);
  }

  async function post(data: PostTicketSm) {
    const result = await axiosRequest<PostTicketVm>(
      `${process.env.VUE_APP_API}/tickets`,
      'POST',
      data,
    );
    return result || defPostTicketVm();
  }

  async function send() {
    const postTicket: PostTicketSm = {
      subject: subject.value,
      content: content.value,
      attachmentTokens,
    };
    const result = await post(postTicket);
    publicLink.value = result.publicLink;
  }

  return {
    subject,
    content,
    attachmentTokens,
    publicLink,
    init,
    addAttachmentToken,
    removeAttachmentToken,
    send,
  };
}
