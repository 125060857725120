









import { defineComponent } from '@vue/composition-api';
import { useReportTicket } from '@/composables';
import { Translation } from '@/translations/Translation';

export default defineComponent({
  setup() {
    const { subject } = useReportTicket();

    return {
      subject,
      Translation,
    };
  },
});
