






















import { defineComponent, ref } from '@vue/composition-api';
import { ViewTemplate, TicketEditor, PublicLink } from '@/components';
import { Translation } from '@/translations/Translation';
import { useReportTicket } from '@/composables';

export default defineComponent({
  components: {
    ViewTemplate,
    TicketEditor,
    PublicLink,
  },
  setup() {
    const { subject, publicLink } = useReportTicket();

    const copyVariant = ref('primary');

    useReportTicket().init();

    return {
      subject,
      publicLink,
      copyVariant,
      Translation,
    };
  },
});
