




















import { defineComponent } from '@vue/composition-api';
import { notifyHelper } from '@bth/standard';
import { useReportTicket } from '@/composables';
import { Translation } from '@/translations/Translation';

export default defineComponent({
  setup() {
    const { publicLink } = useReportTicket();

    function onCopy() {
      notifyHelper.success(Translation.Success, Translation.CopySuccessMessage);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onError(e: any) {
      notifyHelper.danger(Translation.CopyErrorHeader, e);
    }

    function onPublicLinkClick() {
      window.location.href = publicLink.value;
    }

    return {
      publicLink,
      onCopy,
      onError,
      onPublicLinkClick,
    };
  },
});
