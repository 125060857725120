import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import BootstrapVue, { BIconFiles, BIconBoxArrowUpRight } from 'bootstrap-vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueClipboard from 'vue-clipboard2';
import { notifyHelper } from '@bth/standard';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(VueCompositionAPI);
Vue.use(BootstrapVue);
Vue.use(CKEditor);
Vue.use(VueClipboard);
Vue.component('BIconFiles', BIconFiles);
Vue.component('BIconBoxArrowUpRight', BIconBoxArrowUpRight);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');

notifyHelper.init(app);
